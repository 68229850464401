export const adminWebsocketCommands = {
    //route (commands)
    ADMIN_CLIENT_ID_FETCH_COMMAND: 'getClientIds',
    ADMIN_CLIENT_ID_LIST_FETCH_COMMAND: 'getClientIdsList',
    ADMIN_CLIENT_ID_UPDATE_COMMAND: 'updateClientId',
    ADMIN_CLIENT_ID_COPY_COMMAND: 'copyClientId',
    ADMIN_CLIENT_ID_DELETE_COMMAND: 'deleteClientId',
    ADMIN_USER_SETTINGS_SET: 'setUserSettings',
    ADMIN_HELP_FILE_DOWNLOAD: 'helpAttachmentDownload',

    //receive
    ADMIN_CLIENT_ID_FETCH_SUCCESS: 'ADMIN_CLIENT_ID_FETCH_SUCCESS',
    ADMIN_CLIENT_ID_FETCH_ERROR: 'ADMIN_CLIENT_ID_FETCH_ERROR',
    ADMIN_CLIENT_ID_LIST_FETCH_SUCCESS: 'ADMIN_CLIENT_ID_LIST_FETCH_SUCCESS',
    ADMIN_CLIENT_ID_LIST_FETCH_ERROR: 'ADMIN_CLIENT_ID_LIST_FETCH_ERROR',
    ADMIN_ROLES_ACCEPTED: 'ADMIN_ROLES_ACCEPTED',
    ADMIN_ROLES_ERROR: 'ADMIN_ROLES_ERROR',
    ADMIN_CLIENT_IDS_SEND: 'ADMIN_CLIENT_IDS_SEND',
    ADMIN_ATTACHMENT_DOWNLOAD: 'ADMIN_ATTACHMENT_DOWNLOAD',
    ADMIN_ATTACHMENT_DOWNLOAD_ERROR: 'ADMIN_ATTACHMENT_DOWNLOAD_ERROR',
};
