import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    PASSENGER_CAR_PL_TO_21_DAYS: {
      id: 'spareParts.courtesyCar.option.passengerCarPlTo21Days',
      defaultMessage: 'TWINGO, CLIO, CAPTUR, KANGOO, DACIA (all models) - location  -21 days'
    },
    PASSENGER_CAR_PL_OVER_21_DAYS: {
        id: 'spareParts.courtesyCar.option.passengerCarPlOver21Days',
        defaultMessage: 'TWINGO, CLIO, CAPTUR, KANGOO, DACIA (all models) - location  +21 days'
    },
    PASSENGER_CAR_LUX_PL_TO_21_DAYS: {
        id: 'spareParts.courtesyCar.option.passengerCarLuxPlTo21Days',
        defaultMessage: 'MEGANE, KADJAR, SCENIC, GRAND SCENIC, ARKANA micro hybryda, AUSTRAL - location  -21 days'
    },
    PASSENGER_CAR_LUX_PL_OVER_21_DAYS: {
        id: 'spareParts.courtesyCar.option.passengerCarPlLuxPlOver21Days',
        defaultMessage: 'MEGANE, KADJAR, SCENIC, GRAND SCENIC, ARKANA micro hybryda, AUSTRAL - location  +21 days'
    },
    VAN_PL_TO_21_DAYS: {
        id: 'spareParts.courtesyCar.option.vanPlTo21Days',
        defaultMessage: 'ARKANA HEV, MEGANE E-Tech, TALISMAN, KOLEOS, ESPACE, ZOE - location  -21 days'
    },
    VAN_PL_OVER_21_DAYS: {
        id: 'spareParts.courtesyCar.option.vanPlOver21Days',
        defaultMessage: 'ARKANA HEV, MEGANE E-Tech, TALISMAN, KOLEOS, ESPACE, ZOE - location  +21 days'
    },
    VANS_TO_21_DAYS: {
        id: 'spareParts.courtesyCar.option.vanTo21Days',
        defaultMessage: 'TRAFIC, MASTER - location  -21 days'
    },
    VANS_OVER_21_DAYS: {
        id: 'spareParts.courtesyCar.option.vanOver21Days',
        defaultMessage: 'TRAFIC, MASTER - location  +21 days'
    },
    PASSENGER_CAR_PL: {
        id: 'spareParts.courtesyCar.option.passengerCarPl',
        defaultMessage: 'Twingo / Clio / Captur'
    },
    PASSENGER_CAR_DE: {
        id: 'spareParts.courtesyCar.option.passengerCarDe',
        defaultMessage: 'Twingo / Clio / Captur / Kangoo / Twizy / Zoe'
    },
    PASSENGER_CAR_HU: {
        id: 'spareParts.courtesyCar.option.passengerCarHu',
        defaultMessage: 'Twingo / Clio / Captur / Sandero'
    },
    PASSENGER_CAR_LUX_PL: {
        id: 'spareParts.courtesyCar.option.passengerCarLuxPl',
        defaultMessage: 'Megane / Kadjar / Scenic / Grand Scenic'
    },
    PASSENGER_CAR_LUX_DE: {
        id: 'spareParts.courtesyCar.option.passengerCarLuxDe',
        defaultMessage: 'Megane / Scenic / Kadjar'
    },
    PASSENGER_CAR_LUX_HU: {
        id: 'spareParts.courtesyCar.option.passengerCarLuxHu',
        defaultMessage: 'Megane / Kangoo / Fluence / Logan'
    },
    VAN_PL: {
        id: 'spareParts.courtesyCar.option.vanPl',
        defaultMessage: 'Talisman / Koleos / Espace'
    },
    VANS: {
        id: 'spareParts.courtesyCar.option.vans',
        defaultMessage: 'Vans'
    },
    SPECIAL_AGREEMENT_PL: {
        id: 'spareParts.courtesyCar.option.specialAgreementPl',
        defaultMessage: 'LCV CARS'
    },
    VAN_DE: {
        id: 'spareParts.courtesyCar.option.vanDe',
        defaultMessage: 'Talisman / Koleos / Espace / Trafic / Master'
    },
    GVU: {
        id: 'spareParts.courtesyCar.option.gvu',
        defaultMessage: 'GVU'
    },
    VP_PVU: {
        id: 'spareParts.courtesyCar.option.vpPvu',
        defaultMessage: 'VP + PVU'
    },
    VP: {
        id: 'spareParts.courtesyCar.option.vp',
        defaultMessage: 'VP'
    },
    VU1: {
        id: 'spareParts.courtesyCar.option.vu1',
        defaultMessage: 'VU1'
    },
    VU2: {
        id: 'spareParts.courtesyCar.option.vu2',
        defaultMessage: 'VU2'
    },
    UTILITY_VEHICLES_RENAULT: {
        id: 'spareParts.courtesyCar.option.utilityVehiclesRenault',
        defaultMessage: 'Utility Vehicles Renault'
    },
    UTILITY_VEHICLES_DACIA: {
        id: 'spareParts.courtesyCar.option.utilityVehiclesDacia',
        defaultMessage: 'Utility Vehicles Dacia'
    },
    TWINGO: {
        id: 'spareParts.courtesyCar.option.twingo',
        defaultMessage: 'Twingo'
    },
    TWINZY_B: {
        id: 'spareParts.courtesyCar.option.twinzyB',
        defaultMessage: 'Twinzy B'
    },
    CLIO: {
        id: 'spareParts.courtesyCar.option.clio',
        defaultMessage: 'Clio'
    },
    MEGANE: {
        id: 'spareParts.courtesyCar.option.megane',
        defaultMessage: 'Megane'
    },
    KANGOO_VU: {
        id: 'spareParts.courtesyCar.option.kangooVu',
        defaultMessage: 'Kangoo VU'
    },
    KANGOO: {
        id: 'spareParts.courtesyCar.option.kangoo',
        defaultMessage: 'Kangoo'
    },
    KANGOO_VP: {
        id: 'spareParts.courtesyCar.option.kangooVP',
        defaultMessage: 'Kangoo VP'
    },
    SCENIC: {
        id: 'spareParts.courtesyCar.option.scenic',
        defaultMessage: 'Scenic'
    },
    ALASKAN: {
        id: 'spareParts.courtesyCar.option.alaskan',
        defaultMessage: 'Alaskan'
    },
    TALISMAN: {
        id: 'spareParts.courtesyCar.option.talisman',
        defaultMessage: 'Talisman'
    },
    MASTER: {
        id: 'spareParts.courtesyCar.option.master',
        defaultMessage: 'Master'
    },
    TRAFIC: {
        id: 'spareParts.courtesyCar.option.trafic',
        defaultMessage: 'Trafic'
    },
    TRAFIC_5_6M3: {
        id: 'spareParts.courtesyCar.option.trafic56m3',
        defaultMessage: 'Trafic (5-6m3)'
    },
    TRAFIC_9_PLACES: {
        id: 'spareParts.courtesyCar.option.trafic9Places',
        defaultMessage: 'Trafic 9 places'
    },
    RENAULT: {
        id: 'spareParts.courtesyCar.option.renault',
        defaultMessage: 'Renault'
    },
    DACIA: {
        id: 'spareParts.courtesyCar.option.dacia',
        defaultMessage: 'Dacia'
    },
    GAMME_DACIA: {
        id: 'spareParts.courtesyCar.option.gammeDacia',
        defaultMessage: 'Gamme Dacia'
    },
    TARIF_B1: {
        id: 'spareParts.courtesyCar.option.tarifB1',
        defaultMessage: 'Tarif B1'
    },
    TARIF_B2: {
        id: 'spareParts.courtesyCar.option.tarifB2',
        defaultMessage: 'Tarif B2'
    },
    TARIF_B3: {
        id: 'spareParts.courtesyCar.option.tarifB3',
        defaultMessage: 'Tarif B3'
    },
    TARIF_B4: {
        id: 'spareParts.courtesyCar.option.tarifB4',
        defaultMessage: 'Tarif B4'
    },
    TARIF_B5: {
        id: 'spareParts.courtesyCar.option.tarifB5',
        defaultMessage: 'Tarif B5'
    },
    NL1: {
        id: 'spareParts.courtesyCar.option.nl1',
        defaultMessage: 'Twizy, Twingo, Kangoo, Clio, ZOE, Captur, Express, DACIA'
    },
    NL2: {
        id: 'spareParts.courtesyCar.option.nl2',
        defaultMessage: 'Megane, Scenic, Kadjar, Arkana, Austral'
    },
    NL3: {
        id: 'spareParts.courtesyCar.option.nl3',
        defaultMessage: 'Talisman, Koleos, Espace, Alpine'
    },
    NL4: {
        id: 'spareParts.courtesyCar.option.nl4',
        defaultMessage: 'Trafic, Master'
    },
    NL1_2024: {
        id: 'spareParts.courtesyCar.option.nl1_2024',
        defaultMessage: 'Twingo, Kangoo, Clio, ZOE, Captur and Express'
    },
    NL2_2024: {
        id: 'spareParts.courtesyCar.option.nl2_2024',
        defaultMessage: 'Mégane, Scénic, Kadjar, Arkana and Austral'
    },
    NL3_2024: {
        id: 'spareParts.courtesyCar.option.nl3_2024',
        defaultMessage: 'Espace, Rafale, Talisman, Koleos and Alpine'
    },
    NL4_2024: {
        id: 'spareParts.courtesyCar.option.nl4_2024',
        defaultMessage: 'Traffic and Master'
    },
    NL5_2024: {
        id: 'spareParts.courtesyCar.option.nl5_2024',
        defaultMessage: 'Lesauto, use of alternative transport via lesautoverhuur.nl'
    },
    NL6_2024: {
        id: 'spareParts.courtesyCar.option.nl6_2024',
        defaultMessage: 'Dacia Vehicles delivered from June 1, 2019'
    },
    RENTER: {
        id: 'spareParts.courtesyCar.option.renter',
        defaultMessage: 'As a renter'
    },
    COURTESY_CAR: {
        id: 'spareParts.courtesyCar.option.courtesyCar',
        defaultMessage: 'Courtesy Car'
    },
    TWIZY: {
        id: 'spareParts.courtesyCar.option.twizy',
        defaultMessage: 'Dealer Park Twizy'
    },
    ESPACE_4: {
        id: 'spareParts.courtesyCar.option.espace4',
        defaultMessage: 'Espace 4'
    },
    ESPACE_5: {
        id: 'spareParts.courtesyCar.option.espace5',
        defaultMessage: 'Espace 5'
    },
    ENTRY_RANGE: {
        id: 'spareParts.courtesyCar.option.entryRange',
        defaultMessage: 'Entry range Dealer Park'
    },
    HIGH_RANGE: {
        id: 'spareParts.courtesyCar.option.highRange',
        defaultMessage: 'High range Dealer Park'
    },
    TRAFIC_MASTER: {
        id: 'spareParts.courtesyCar.option.traficMaster',
        defaultMessage: 'Dealer Park Trafic/Master'
    },
    TARIF_A: {
        id: 'spareParts.courtesyCar.option.tarifA',
        defaultMessage: 'A (Twingo / Clio / Modus / Twizzy)'
    },
    TARIF_B: {
        id: 'spareParts.courtesyCar.option.tarifB',
        defaultMessage: 'B (Megane / Scenic / Kangoo VP+VU)'
    },
    TARIF_C: {
        id: 'spareParts.courtesyCar.option.tarifC',
        defaultMessage: 'C (Laguna / Espace)'
    },
    VAN_SE: {
        id: 'spareParts.courtesyCar.option.vanSe',
        defaultMessage: 'Bus / Van / Alaskan'
    },
    ALL_TYPE_OF_VEHICLES: {
        id: 'spareParts.courtesyCar.option.allTypeOfVehicles',
        defaultMessage: 'All type of vehicles'
    },
    SCENIC_ZOE_KADJAR_DOKKER_DUSTER_LODGY_HU: {
        id: 'spareParts.courtesyCar.option.scenicZoeKadjarDokkerDusterLodgyHu',
        defaultMessage: 'Scenic / Zoe / Kadjar / Dokker / Duster / Lodgy'
    },
    LAGUNA_TALISMAN_ESPACE_KOLEOS_HU: {
        id: 'spareParts.courtesyCar.option.lagunaTalismanEspaceKoleosHu',
        defaultMessage: 'Laguna / Talisman / Espace / Koleos'
    },
    TRAFIC_MASTER_HU: {
        id: 'spareParts.courtesyCar.option.traficMasterHu',
        defaultMessage: 'Trafic / Master'
    },
    DEALER_PARK: {
        id: 'spareParts.courtesyCar.option.dealerPark',
        defaultMessage: 'Dealer Park'
    },
    A_TWINGO_CLIO_CAPTUR: {
        id: 'spareParts.courtesyCar.option.aTwingoClioCaptur',
        defaultMessage: 'A (Twingo / Clio / Captur)'
    },
    B_MEGANE_KANGOO_FLU: {
        id: 'spareParts.courtesyCar.option.bMeganeKangooFlu',
        defaultMessage: 'B (Megane, Kangoo, Flu)'
    },
    C_SCENIC_ZOE_KADJAR: {
        id: 'spareParts.courtesyCar.option.cScenicZoeKadjar',
        defaultMessage: 'C (Scenic, Zoe, Kadjar)'
    },
    D_TALISMAN_ESPACE: {
        id: 'spareParts.courtesyCar.option.dTalismanEspace',
        defaultMessage: 'D (Talisman, Espace)'
    },
    VU_TRAFIC_MASTER: {
        id: 'spareParts.courtesyCar.option.vuTraficMaster',
        defaultMessage: 'VU (Trafic, Master)'
    },
    SANDERO: {
        id: 'spareParts.courtesyCar.option.sandero',
        defaultMessage: 'Sandero'
    },
    JOGGER: {
        id: 'spareParts.courtesyCar.option.jogger',
        defaultMessage: 'Jogger'
    },
    DOKKER_VAN: {
        id: 'spareParts.courtesyCar.option.dokkerVan',
        defaultMessage: 'Dokker Van'
    },
    ALPINE_CAR: {
        id: 'spareParts.courtesyCar.option.alpineCar',
        defaultMessage: 'Alpine car',
    },
    ARKANA_CAR: {
        id: 'spareParts.courtesyCar.option.arkanaCar',
        defaultMessage: 'Arkana car',
    },
    AUSTRAL_CAR: {
        id: 'spareParts.courtesyCar.option.australCar',
        defaultMessage: 'Austral car',
    },
    CAPTUR_CAR: {
        id: 'spareParts.courtesyCar.option.capturCar',
        defaultMessage: 'Captur car',
    },
    CLIO_CAR: {
        id: 'spareParts.courtesyCar.option.clioCar',
        defaultMessage: 'Clio car',
    },
    DUSTER_CAR: {
        id: 'spareParts.courtesyCar.option.dusterCar',
        defaultMessage: 'Duster car',
    },
    ESPACE_CAR: {
        id: 'spareParts.courtesyCar.option.espaceCar',
        defaultMessage: 'Espace car',
    },
    FLUENCE_CAR: {
        id: 'spareParts.courtesyCar.option.fluenceCar',
        defaultMessage: 'Fluence car',
    },
    JOGGER_CAR: {
        id: 'spareParts.courtesyCar.option.joggerCar',
        defaultMessage: 'Jogger car',
    },
    KADJAR_CAR: {
        id: 'spareParts.courtesyCar.option.kadjarCar',
        defaultMessage: 'Kadjar car',
    },
    KANGOO_CAR: {
        id: 'spareParts.courtesyCar.option.kangooCar',
        defaultMessage: 'Kangoo car',
    },
    KANGOO_VAN: {
        id: 'spareParts.courtesyCar.option.kangooVan',
        defaultMessage: 'Kangoo van',
    },
    KOLEOS_CAR: {
        id: 'spareParts.courtesyCar.option.koleosCar',
        defaultMessage: 'Koleos car',
    },
    LAGUNA_CAR: {
        id: 'spareParts.courtesyCar.option.lagunaCar',
        defaultMessage: 'Laguna car',
    },
    LOGAN_MCV_CAR: {
        id: 'spareParts.courtesyCar.option.loganMcvCar',
        defaultMessage: 'Logan MCV car',
    },
    MASTER_VAN: {
        id: 'spareParts.courtesyCar.option.masterVan',
        defaultMessage: 'Master van',
    },
    MEGANE_CAR: {
        id: 'spareParts.courtesyCar.option.meganeCar',
        defaultMessage: 'Megane car',
    },
    MODUS_CAR: {
        id: 'spareParts.courtesyCar.option.modusCar',
        defaultMessage: 'Modus car',
    },
    SANDERO_CAR: {
        id: 'spareParts.courtesyCar.option.sanderoCar',
        defaultMessage: 'Sandero car',
    },
    SANDERO_STEPWAY_CAR: {
        id: 'spareParts.courtesyCar.option.sanderoStepwayCar',
        defaultMessage: 'Sandero Stepway car',
    },
    SCENIC_CAR: {
        id: 'spareParts.courtesyCar.option.scenicCar',
        defaultMessage: 'Scenic car',
    },
    TRAFIC_VAN: {
        id: 'spareParts.courtesyCar.option.traficVan',
        defaultMessage: 'Trafic van',
    },
    TRAFIC_PASSENGER_CAR: {
        id: 'spareParts.courtesyCar.option.traficPassengerCar',
        defaultMessage: 'Trafic Passenger car',
    },
    TWINGO_CAR: {
        id: 'spareParts.courtesyCar.option.twingoCar',
        defaultMessage: 'Twingo car',
    },
    TWIZZY_CAR: {
        id: 'spareParts.courtesyCar.option.twizzyCar',
        defaultMessage: 'Twizzy car',
    },
    WIND_CAR: {
        id: 'spareParts.courtesyCar.option.windCar',
        defaultMessage: 'Wind car',
    },
    ZOE_CAR: {
        id: 'spareParts.courtesyCar.option.zoeCar',
        defaultMessage: 'Zoe car',
    },
    ZOE_VAN_VAN: {
        id: 'spareParts.courtesyCar.option.zoeVanVan',
        defaultMessage: 'Zoe Van van',
    },
    CLIO_V_AUTOMATIC: {
        id: 'spareParts.courtesyCar.option.clio5Automatic',
        defaultMessage: 'Clio 5 (Manual type)',
    },
    CLIO_V_MANUAL: {
        id: 'spareParts.courtesyCar.option.clio5Manual',
        defaultMessage: 'Clio 5 (Automatic type)',
    },
    RENAULT_MODEL: {
        id: 'spareParts.courtesyCar.option.renaultModel',
        defaultMessage: 'PC: Passenger car - All Renault models'
    },
    DACIA_MODEL: {
        id: 'spareParts.courtesyCar.option.daciaModel',
        defaultMessage: 'PC: Passenger car - All Dacia models'
    },
    VAN_DK: {
        id: 'spareParts.courtesyCar.option.van_dk',
        defaultMessage: 'LCV: Bus/Van/Transport – Trafic, Master and Express'
    },
    VAN_SE_NEW: {
        id: 'spareParts.courtesyCar.option.van_se_new',
        defaultMessage: 'LCV: Bus/Van/Transport – Trafic, Master, Express and Alaskan'
    },
    ARKANA_PHAS1: {
        id: 'spareParts.courtesyCar.option.arkana_phas1',
        defaultMessage: 'ARKANA LJL PHAS1 '
    },
    CLIO_PHAS1: {
        id: 'spareParts.courtesyCar.option.clio_phas1',
        defaultMessage: 'CLIO V BJA PHAS1 '
    },
    JOGGER_PHAS1: {
        id: 'spareParts.courtesyCar.option.jogger_phas1',
        defaultMessage: 'Jogger RJI PHAS1'
    }
});

export default messages;
