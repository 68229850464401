export const HOME_PATH = '/';

export const PROFILE_PATH = '/profile';

export const ADMINISTRATION_PATH = '/administration';
export const HELP_PATH = '/help';

export const ADMINISTRATION_CLIENT_ID_PATH = `${ADMINISTRATION_PATH}/clientId`;

export const ORDERS_PATH = '/order';
export const ORDERS_NEW_PATH = `${ORDERS_PATH}/new`;
export const ORDERS_LIST_PATH = `${ORDERS_PATH}/list`;
export const ORDERS_EXPORT_PATH = `${ORDERS_PATH}/export`;
export const ORDERS_HISTORY_PATH = `${ORDERS_PATH}/history`;
export const ORDERS_SEARCH_ENGINE_PATH = `${ORDERS_PATH}/searchEngine`;
export const ORDERS_RECURRENT_PROBLEM_PATH = `${ORDERS_PATH}/recurrentProblem`;
export const ORDERS_MANAGEMENT_GROUP = `${ORDERS_PATH}/managementGroup`;
export const ORDERS_INTERNAL_TICKET = `${ORDERS_PATH}/internalTicket`;
export const ORDERS_INTERNAL_TICKET_LIST_PATH = `${ORDERS_INTERNAL_TICKET}/list`;
export const ORDERS_INTERNAL_TICKET_HISTORY_PATH = `${ORDERS_INTERNAL_TICKET}/history`;
export const ORDERS_INTERNAL_TICKET_SEARCH_PATH = `${ORDERS_INTERNAL_TICKET}/fullTextSearch`;

export const ORDERS_NEW_DELIVERY_DATE_REMINDER_FORM_PATH = `${ORDERS_NEW_PATH}/DeliveryDateReminderForm`;
export const ORDERS_NEW_ALLOCATION_REQUEST_FORM_PATH = `${ORDERS_NEW_PATH}/AllocationRequestForm`;
export const ORDERS_NEW_CANCELLATION_FORM_PATH = `${ORDERS_NEW_PATH}/OrderCancellationForm`;
export const ORDERS_NEW_PARTS_NON_AVAILABLE_FORM_PATH = `${ORDERS_NEW_PATH}/PartNoLongerAvailableForm`;
export const ORDERS_NEW_VOR_PVI_FORM_PATH = `${ORDERS_NEW_PATH}/VorPviOrderForm`;
export const ORDERS_NEW_EV_BATTERY_FORM_PATH = `${ORDERS_NEW_PATH}/EVBatteryForm`;
export const ORDERS_NEW_INTERNAL_TICKET = `${ORDERS_INTERNAL_TICKET}/new`;

export const ADMINISTRATION_ORDER_DEALER_NOTIFICATION_PATH = `${ADMINISTRATION_PATH}${ORDERS_PATH}/dealerNotification`;
export const ADMINISTRATION_ORDER_TICKET_SETTING_PATH = `${ADMINISTRATION_PATH}${ORDERS_PATH}/ticketSetting`;
export const ADMINISTRATION_ORDER_DEFAULT_COUNTRY_ROLE_PATH = `${ADMINISTRATION_PATH}${ORDERS_PATH}/defaultCountryRole`;
export const ADMINISTRATION_ORDER_RECURRENT_PROBLEM_PATH = `${ADMINISTRATION_PATH}${ORDERS_PATH}/recurrentProblem`;
export const ADMINISTRATION_ORDER_MAIN_PAGE_PATH = `${ADMINISTRATION_PATH}${ORDERS_PATH}/mainPage`;
export const ADMINISTRATION_ORDER_WORKING_HOUR_PATH = `${ADMINISTRATION_PATH}${ORDERS_PATH}/workingHour`;
export const ADMINISTRATION_ORDER_SECOND_HAND_BATTERY_TYPE_PATH = `${ADMINISTRATION_PATH}${ORDERS_PATH}/secondHandBatteryType`;
export const ADMINISTRATION_ORDER_ADMIN_PATH = `${ADMINISTRATION_PATH}${ORDERS_PATH}/admin`;
export const ADMINISTRATION_ORDER_DEALER_PATH = `${ADMINISTRATION_PATH}${ORDERS_PATH}/dealer`;
export const ADMINISTRATION_ORDER_EDITOR_PATH = `${ADMINISTRATION_PATH}${ORDERS_PATH}/editor`;
export const ADMINISTRATION_ORDER_SOLUTION_GROUP_PATH = `${ADMINISTRATION_PATH}${ORDERS_PATH}/solutionGroup`;
export const ADMINISTRATION_ORDER_SOLUTION_GROUP_NEW_PATH = `${ADMINISTRATION_ORDER_SOLUTION_GROUP_PATH}/new`; // here
export const ADMINISTRATION_ORDER_SOLUTION_GROUP_LIST_PATH = `${ADMINISTRATION_ORDER_SOLUTION_GROUP_PATH}/list`;
export const ADMINISTRATION_ORDER_SOLUTION_GROUP_USER_PATH = `${ADMINISTRATION_ORDER_SOLUTION_GROUP_PATH}/user`;
export const ADMINISTRATION_ORDER_SOLUTION_GROUP_MODEL_ANSWERS_SETTINGS_PATH = `${ADMINISTRATION_ORDER_SOLUTION_GROUP_PATH}/modelAnswersSettings`;
export const ADMINISTRATION_ORDER_ESCALATION_SCHEMA_PATH = `${ADMINISTRATION_PATH}${ORDERS_PATH}/escalationSchema`;
export const ADMINISTRATION_ORDER_ESCALATION_SCHEMA_NEW_PATH = `${ADMINISTRATION_ORDER_ESCALATION_SCHEMA_PATH}/new`;
export const ADMINISTRATION_ORDER_ESCALATION_SCHEMA_LIST_PATH = `${ADMINISTRATION_ORDER_ESCALATION_SCHEMA_PATH}/list`;
export const ADMINISTRATION_ORDER_ANSWERING_SCHEMA_PATH = `${ADMINISTRATION_PATH}${ORDERS_PATH}/answeringSchema`;
export const ADMINISTRATION_ORDER_ANSWERING_SCHEMA_NEW_PATH = `${ADMINISTRATION_ORDER_ANSWERING_SCHEMA_PATH}/new`;
export const ADMINISTRATION_ORDER_ANSWERING_SCHEMA_LIST_PATH = `${ADMINISTRATION_ORDER_ANSWERING_SCHEMA_PATH}/list`;

export const DOCUMENTATIONS_PATH = '/documentation';
export const DOCUMENTATIONS_NEW_PATH = `${DOCUMENTATIONS_PATH}/new`;
export const DOCUMENTATIONS_LIST_PATH = `${DOCUMENTATIONS_PATH}/list`;
export const DOCUMENTATIONS_EXPORT_PATH = `${DOCUMENTATIONS_PATH}/export`;
export const DOCUMENTATIONS_HISTORY_PATH = `${DOCUMENTATIONS_PATH}/history`;
export const DOCUMENTATIONS_FULL_TEXT_SEARCH_PATH = `${DOCUMENTATIONS_PATH}/fullTextSearch`;
export const DOCUMENTATIONS_RECURRENT_PROBLEM_PATH = `${DOCUMENTATIONS_PATH}/recurrentProblem`;
export const DOCUMENTATIONS_MANAGEMENT_GROUP_PATH = `${DOCUMENTATIONS_PATH}/managementGroup`;
export const DOCUMENTATIONS_INTERNAL_TICKET = `${DOCUMENTATIONS_PATH}/internalTicket`;
export const DOCUMENTATIONS_INTERNAL_TICKET_LIST_PATH = `${DOCUMENTATIONS_INTERNAL_TICKET}/list`;
export const DOCUMENTATIONS_INTERNAL_TICKET_HISTORY_PATH = `${DOCUMENTATIONS_INTERNAL_TICKET}/history`;
export const DOCUMENTATIONS_INTERNAL_TICKET_SEARCH_PATH = `${DOCUMENTATIONS_INTERNAL_TICKET}/fullTextSearch`;
export const DOCUMENTATIONS_INTERNAL_TICKET_EXPORT_PATH = `${DOCUMENTATIONS_INTERNAL_TICKET}/export`;

export const DOCUMENTATIONS_NEW_REQUEST_FOR_NOT_FOUND_REFERENCE = `${DOCUMENTATIONS_NEW_PATH}/RequestForNotFoundReference`;
export const DOCUMENTATIONS_NEW_CODED_PARTS_KEYS = `${DOCUMENTATIONS_NEW_PATH}/CodedPartsKeys`;
export const DOCUMENTATIONS_NEW_OVAL_PLATE_ORDER = `${DOCUMENTATIONS_NEW_PATH}/OvalPlateOrder`;
export const DOCUMENTATIONS_NEW_REQUEST_FOR_NOT_CONFORM_REFERENCE = `${DOCUMENTATIONS_NEW_PATH}/RequestForNotConformReference`;
export const DOCUMENTATIONS_NEW_UNKNOWN_PART_OPENING_REQUEST = `${DOCUMENTATIONS_NEW_PATH}/UnknownPartOpeningRequest`;
export const DOCUMENTATIONS_NEW_INTERNAL_TICKET = `${DOCUMENTATIONS_INTERNAL_TICKET}/new`;

export const ADMINISTRATION_DOCUMENTATIONS_DEALER_NOTIFICATION_PATH = `${ADMINISTRATION_PATH}${DOCUMENTATIONS_PATH}/dealerNotification`;
export const ADMINISTRATION_DOCUMENTATIONS_TICKET_SETTING_PATH = `${ADMINISTRATION_PATH}${DOCUMENTATIONS_PATH}/ticketSetting`;
export const ADMINISTRATION_DOCUMENTATIONS_DEFAULT_COUNTRY_ROLE_PATH = `${ADMINISTRATION_PATH}${DOCUMENTATIONS_PATH}/defaultCountryRole`;
export const ADMINISTRATION_DOCUMENTATIONS_RECURRENT_PROBLEM_PATH = `${ADMINISTRATION_PATH}${DOCUMENTATIONS_PATH}/recurrentProblem`;
export const ADMINISTRATION_DOCUMENTATIONS_MAIN_PAGE_PATH = `${ADMINISTRATION_PATH}${DOCUMENTATIONS_PATH}/mainPage`;
export const ADMINISTRATION_DOCUMENTATIONS_WORKING_HOUR_PATH = `${ADMINISTRATION_PATH}${DOCUMENTATIONS_PATH}/workingHour`;
export const ADMINISTRATION_DOCUMENTATIONS_ADMIN_PATH = `${ADMINISTRATION_PATH}${DOCUMENTATIONS_PATH}/admin`;
export const ADMINISTRATION_DOCUMENTATIONS_DEALER_PATH = `${ADMINISTRATION_PATH}${DOCUMENTATIONS_PATH}/dealer`;
export const ADMINISTRATION_DOCUMENTATIONS_EDITOR_PATH = `${ADMINISTRATION_PATH}${DOCUMENTATIONS_PATH}/editor`;
export const ADMINISTRATION_DOCUMENTATIONS_ESCALATION_LEVELS_PATH = `${ADMINISTRATION_PATH}${DOCUMENTATIONS_PATH}/escalationLevels`;
export const ADMINISTRATION_DOCUMENTATIONS_SOLUTION_GROUP_PATH = `${ADMINISTRATION_PATH}${DOCUMENTATIONS_PATH}/solutionGroup`;
export const ADMINISTRATION_DOCUMENTATIONS_SOLUTION_GROUP_NEW_PATH = `${ADMINISTRATION_DOCUMENTATIONS_SOLUTION_GROUP_PATH}/new`;
export const ADMINISTRATION_DOCUMENTATIONS_SOLUTION_GROUP_LIST_PATH = `${ADMINISTRATION_DOCUMENTATIONS_SOLUTION_GROUP_PATH}/list`;
export const ADMINISTRATION_DOCUMENTATIONS_SOLUTION_GROUP_USER_PATH = `${ADMINISTRATION_DOCUMENTATIONS_SOLUTION_GROUP_PATH}/user`;
export const ADMINISTRATION_DOCUMENTATIONS_SOLUTION_GROUP_MODEL_ANSWERS_SETTINGS_PATH = `${ADMINISTRATION_DOCUMENTATIONS_SOLUTION_GROUP_PATH}/modelAnswersSettings`;
export const ADMINISTRATION_DOCUMENTATIONS_ESCALATION_SCHEMA_PATH = `${ADMINISTRATION_PATH}${DOCUMENTATIONS_PATH}/escalationSchema`;
export const ADMINISTRATION_DOCUMENTATIONS_ESCALATION_SCHEMA_NEW_PATH = `${ADMINISTRATION_DOCUMENTATIONS_ESCALATION_SCHEMA_PATH}/new`;
export const ADMINISTRATION_DOCUMENTATIONS_ESCALATION_SCHEMA_LIST_PATH = `${ADMINISTRATION_DOCUMENTATIONS_ESCALATION_SCHEMA_PATH}/list`;
export const ADMINISTRATION_DOCUMENTATIONS_ANSWERING_SCHEMA_PATH = `${ADMINISTRATION_PATH}${DOCUMENTATIONS_PATH}/answeringSchema`;
export const ADMINISTRATION_DOCUMENTATIONS_ANSWERING_SCHEMA_NEW_PATH = `${ADMINISTRATION_DOCUMENTATIONS_ANSWERING_SCHEMA_PATH}/new`;
export const ADMINISTRATION_DOCUMENTATIONS_ANSWERING_SCHEMA_LIST_PATH = `${ADMINISTRATION_DOCUMENTATIONS_ANSWERING_SCHEMA_PATH}/list`;

export const CLAIMS_PATH = '/claims';
export const CLAIMS_NEW_PATH = `${CLAIMS_PATH}/new`;
export const CLAIMS_LIST_PATH = `${CLAIMS_PATH}/list`;
export const CLAIMS_EXPORT_PATH = `${CLAIMS_PATH}/export`;
export const CLAIMS_HISTORY_PATH = `${CLAIMS_PATH}/history`;
export const CLAIMS_FULL_TEXT_SEARCH_PATH = `${CLAIMS_PATH}/fullTextSearch`;
export const CLAIMS_RECURRENT_PROBLEM_PATH = `${CLAIMS_PATH}/recurrentProblem`;


export const CLAIMS_NEW_CONTEST_CLAIM_STATUS = `${CLAIMS_NEW_PATH}/ContestClaimStatus`;
export const CLAIMS_NEW_PROBLEM_FOR_PLACING_CLAIMS = `${CLAIMS_NEW_PATH}/ProblemForPlacingClaims`;
export const CLAIMS_NEW_RETURN_CLAIM = `${CLAIMS_NEW_PATH}/ReturnClaim`;
export const CLAIMS_NEW_STOCK_DEAD_CLAIM = `${CLAIMS_NEW_PATH}/StockDeadClaim`;
export const CLAIMS_NEW_OLD_MATERIAL_CLAIM = `${CLAIMS_NEW_PATH}/OldMaterialClaim`;
export const CLAIMS_NEW_DIRECT_FLOW_CLAIM = `${CLAIMS_NEW_PATH}/DirectFlowClaim`;
export const CLAIMS_NEW_TROUBLESHOOT_CLAIM = `${CLAIMS_NEW_PATH}/Troubleshoot`;

export const ADMINISTRATION_CLAIMS_DEALER_NOTIFICATION_PATH = `${ADMINISTRATION_PATH}${CLAIMS_PATH}/dealerNotification`;
export const ADMINISTRATION_CLAIMS_TICKET_SETTING_PATH = `${ADMINISTRATION_PATH}${CLAIMS_PATH}/ticketSetting`;
export const ADMINISTRATION_CLAIMS_RECURRENT_PROBLEM_PATH = `${ADMINISTRATION_PATH}${CLAIMS_PATH}/recurrentProblem`;
export const ADMINISTRATION_CLAIMS_MAIN_PAGE_PATH = `${ADMINISTRATION_PATH}${CLAIMS_PATH}/mainPage`;
export const ADMINISTRATION_CLAIMS_WORKING_HOUR_PATH = `${ADMINISTRATION_PATH}${CLAIMS_PATH}/workingHour`;
export const ADMINISTRATION_CLAIMS_PRESET_RESPONSE_PATH = `${ADMINISTRATION_PATH}${CLAIMS_PATH}/presetResponse`;
export const ADMINISTRATION_CLAIMS_DEFAULT_COUNTRY_ROLE_PATH = `${ADMINISTRATION_PATH}${CLAIMS_PATH}/defaultCountryRole`;
export const ADMINISTRATION_CLAIMS_ADMIN_PATH = `${ADMINISTRATION_PATH}${CLAIMS_PATH}/admin`;
export const ADMINISTRATION_CLAIMS_DEALER_PATH = `${ADMINISTRATION_PATH}${CLAIMS_PATH}/dealer`;
export const ADMINISTRATION_CLAIMS_EDITOR_PATH = `${ADMINISTRATION_PATH}${CLAIMS_PATH}/editor`;


export const GENERICS_PATH = '/generic';
export const DETAIL_PATH = 'detail';

export const ADMINISTRATION_GENERIC_SOLUTION_GROUP_PATH = `${ADMINISTRATION_PATH}${GENERICS_PATH}/solutionGroup`;
export const ADMINISTRATION_GENERIC_FLOW_PATH = `${ADMINISTRATION_PATH}${GENERICS_PATH}/flow`;
export const ADMINISTRATION_GENERIC_FLOW_DETAIL_PATH = `${ADMINISTRATION_GENERIC_FLOW_PATH}/${DETAIL_PATH}/:id?`;
