import React from 'react';
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';
import InputSelectFieldWithValidation from '../../../../../common/InputSelectFieldWithValidation';
import {TextareaFieldWithValidation} from '../../../../../common/TextareaFieldWithValidation';
import {
    FORM_FIELD_CAR_LOCATION,
    FORM_FIELD_COURTESY_CAR_TYPE,
    FORM_FIELD_REIMBURSEMENT,
    FORM_FIELD_SUPPLEMENTARY_INFORMATION,
    FORM_LABEL_COURTESY_CAR_TYPE,
    FORM_LABEL_REIMBURSEMENT,
    FORM_LABEL_SUPPLEMENTARY_INFORMATION,
    FORM_CAR_REQUEST,
    FORM_CAR_REQUEST_SECTION,
    FORM_VEHICLE_DETAIL_SECTION, FORM_LABEL_COURTESY_NOTES_HISTORY
} from '../../../../../../constants/formConstants';
import {FieldArray, formValueSelector, getFormInitialValues} from 'redux-form';
import {connect} from 'react-redux';
import CarCourtesyTableRow from './CarCourtesyTableRow';
import commonMessages from '../../../../../../intl/common/commonMessages';
import ValidFormattedMessage from '../../../../../common/ValidFormattedMessage';
import formMessages from '../../../../../../intl/sparePartsOrders/formMessages';
import courtesyCarsMessages from '../../../../../../intl/sparePartsOrders/courtesyCarsMessages';
import {courtesyCars} from '../../../../../../constants/Utils';
import get from 'get-value';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const StyledHistoryDiv = styled.div`
  border-radius: 5px 5px 0 0;
  background-color: #e9ecef;
`;

class CarCourtesySection extends React.Component {
    renderContent = ({fields}) => {
        const {userRights, readOnly, form} = this.props;
        return (
            <div>
                <StyledHistoryDiv className="border-bottom border-bottom-secondary">
                    <label className="col-form-label pl-0 font-weight-bold ml-3">
                        <ValidFormattedMessage message={FORM_LABEL_COURTESY_NOTES_HISTORY} intlMessages={formMessages}/>
                    </label>
                </StyledHistoryDiv>
                {fields.map((request, index) =>
                    <CarCourtesyTableRow key={index} index={index} request={request} userRights={userRights}
                                         readOnly={readOnly} form={form}/>
                )}
            </div>
        )
    };

    render() {
        const {carRequests, repairedCarLocation, userRights, readOnly, requestCarCourtesy, reqFields, initialValues} = this.props;
        return (
            <>
                {(((repairedCarLocation === 'OWN_WORKSHOP' || repairedCarLocation === 'SUBSIDIARY') && userRights.canEditCourCarReq && !readOnly) || (carRequests && carRequests.length > 0)) &&
                <>
                    <h3 className="text-left mt-4 ml-4">
                        <FormattedMessage {...formMessages.WAREHOUSE_SECTION}/>
                    </h3>
                    <StyledDiv className="border border-secondary bg-white overflow-hidden">
                        {!!carRequests && carRequests.length > 0 &&
                        <FieldArray name={FORM_CAR_REQUEST} component={this.renderContent}/>
                        }
                        {!readOnly && (repairedCarLocation === 'OWN_WORKSHOP' || repairedCarLocation === 'SUBSIDIARY') && userRights.canEditCourCarReq && !readOnly &&
                        <>
                            <div className="p-4">
                                <div className="form-row">
                                    <div className="col-sm-12 col-xl-8">
                                        <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_REIMBURSEMENT]}
                                                                        field={FORM_FIELD_REIMBURSEMENT}
                                                                        options={['YES', 'NO']}
                                                                        intlMessages={commonMessages}
                                                                        isRequired={reqFields.includes(FORM_FIELD_REIMBURSEMENT)}/>
                                    </div>
                                    <div className="col">
                                        <InputSelectFieldWithValidation
                                            label={formMessages[FORM_LABEL_COURTESY_CAR_TYPE]}
                                            field={FORM_FIELD_COURTESY_CAR_TYPE}
                                            options={get(courtesyCars, `${get(initialValues, 'ticketSection.group', {default: 'X'})}`, {default: []})}
                                            intlMessages={courtesyCarsMessages}
                                            sort={false}
                                            isRequired={(requestCarCourtesy && requestCarCourtesy === 'YES')}/>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-12 pb-2">
                                        <TextareaFieldWithValidation
                                            label={formMessages[FORM_LABEL_SUPPLEMENTARY_INFORMATION]}
                                            field={FORM_FIELD_SUPPLEMENTARY_INFORMATION}
                                            rows={3}/>
                                    </div>
                                </div>
                            </div>
                        </>
                        }
                    </StyledDiv>
                </>
                }
            </>
        )
    }
}

const mapStateToProps = (state, initialProps) => ({
    carRequests: formValueSelector(initialProps.form)(state, `${FORM_CAR_REQUEST_SECTION}.${FORM_CAR_REQUEST}`),
    repairedCarLocation: formValueSelector(initialProps.form)(state, `${FORM_VEHICLE_DETAIL_SECTION}.${FORM_FIELD_CAR_LOCATION}`),
    requestCarCourtesy: formValueSelector(initialProps.form)(state, `${FORM_CAR_REQUEST_SECTION}.${FORM_FIELD_REIMBURSEMENT}`),
    initialValues: getFormInitialValues(initialProps.form)(state),
});

export default connect(mapStateToProps, null)(CarCourtesySection);
