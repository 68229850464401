import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';
import * as serviceWorker from './serviceWorker';
import Root from './components/Root';
import configureStore from './store/configureStore';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faArrowsAlt,
    faArrowsAltV,
    faBars,
    faCheck,
    faCheckCircle,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faCog,
    faCopy,
    faDownload,
    faExclamationCircle,
    faHome,
    faInfoCircle,
    faPen,
    faPencilAlt,
    faPlus,
    faSort,
    faSortDown,
    faSortUp,
    faTimes,
    faTrashAlt
} from '@fortawesome/free-solid-svg-icons';

library.add(
    faDownload, faTrashAlt, faArrowsAlt, faPencilAlt, faChevronLeft, faChevronRight, faCheck, faArrowsAltV, faTimes,
    faSort, faSortUp, faSortDown, faChevronDown, faPen, faHome, faCog, faBars, faExclamationCircle, faInfoCircle,
    faCheckCircle, faPlus, faCopy
);

export const store = configureStore();
const rootEl = document.getElementById('root');
ReactDOM.render(
    <Root store={store}/>,
    rootEl,
);
//
// if (module.hot) {
//     module.hot.accept('./components/Root', () => {
//         ReactDOM.render(<Root store={store}/>, rootEl);
//     });
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
